<template>
  <div class="gewerkeauswahl">
    <div class="row">
      <div class="flex md12">
        <va-card outlined>
          <template slot="header">
            <h5 class="mt-0 mb-0">{{ $t('gewerke.title') }}</h5>
          </template>
          <div class="row">
            <va-notification class="flex md10" v-if="currState.msgType=='success'" color="success">
              <va-badge color="success">
                {{ $t('common.notificationSuccess') }}
              </va-badge>
              {{ currState.msg }}
            </va-notification>
            <va-notification class="flex md10" v-if="currState.msgType=='error'" color="danger">
              <va-badge color="danger">
                {{ $t('common.notificationError') }}
              </va-badge>
              {{ currState.msg }}
            </va-notification>
          </div>
        </va-card>
      </div>
    </div>
    <va-modal v-model="toDelete.showDeleteModal"
      :message="toDelete.msg"
      title="$t('anmelden.signout')"
      :ok-text="$t('common.yes')"
      :cancel-text="$t('common.no')"
      @ok="deleteUserKiEvent()"/>
    <va-card id="va-card-choice">
      <div class="row">
        <div class="flex md1">
          <va-button small @click="saveUserChoices()"> {{ $t('common.save') }}</va-button>
        </div>
        <div class="flex">
        </div>
      </div>
      <div class="va-table-responsive">
        <table class="va-table" id="va-table-choice">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>{{ $t('gewerke.gewerk') }}</th>
              <th>{{ $t('choices.select') }}</th>
              <th>{{ $t('common.others') }}</th>
              <th>{{ $t('common.comment') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="subs in userChoices" :key="subs.userChoiceID">
              <td>
                <va-button small color="info" @click="addNewSub(subs)" class="mr-3 pr-2 pl-2">+</va-button>
              </td>
              <td>{{ subs.choiceLabel }}</td>
              <td><va-checkbox v-model="subs.choiceBool" /></td>
              <td><va-input v-model="subs.otherUser" class="input-choicetext" :placeholder="currentUser" /></td>
              <td><va-input v-model="subs.comments" class="input-choicetext" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </va-card>
  </div>
</template>

<script>

export default {
  name: 'gewerkeauswahl',
  data () {
    this.MSG_PREFIX = 'gewerke'

    this.$srvconn.sendMessage(this.MSG_PREFIX + 'getUserChoices')
      .then(response => {
        if (response.msgType === this.MSG_PREFIX + 'getUserChoices') {
          this.userChoices = response.userData.userChoices
        }
        // console.log(response)
      })
      .catch(err => {
        console.log(err)
      })

    return {
      userChoices: [],
      currState: {
        msgType: '',
        msg: '',
      },
      toDelete: {
        showDeleteModal: false,
        msg: '',
      },
      currentUser: this.$userauth.loggedInUser(),
    }
  },
  methods: {
    mounted () {
    },
    addNewSub (subs) {
      var newSub = {
        userChoiceID: -1,
        choiceID: subs.choiceID,
        choiceLabel: subs.choiceLabel,
        choiceValue: 0,
        choiceBool: false,
        otherUser: '',
        comments: '',
      }
      for (var i = 0; i < this.userChoices.length; i++) {
        if (this.userChoices[i].choiceID === subs.choiceID) {
          this.userChoices.splice(i + 1, 0, newSub)
          break
        }
      }
    },
    saveUserChoices () {
      var toSave = []
      console.log(this.userChoices)
      for (var i = 0; i < this.userChoices.length; i++) {
        if ((this.userChoices[i].choiceBool) || (this.userChoices[i].userChoiceID > 0)) {
          var newEntry = {}
          newEntry.userChoiceID = this.userChoices[i].userChoiceID
          newEntry.choiceID = this.userChoices[i].choiceID
          newEntry.choiceValue = (this.userChoices[i].choiceBool ? 1 : 0)
          newEntry.otherUser = this.userChoices[i].otherUser
          newEntry.comments = this.userChoices[i].comments
          toSave.push(newEntry)
        }
      }
      console.log(toSave)

      this.$srvconn.sendMessage(this.MSG_PREFIX + 'saveUserChoices', toSave)
        .then(response => {
          if (response.msgType === 'error') {
            this.currState.msgType = 'error'
            this.currState.msg = response.userData
          } else {
            this.currState.msgType = 'success'
            this.currState.msg = this.$t('common.saveSuccess')
            this.userChoices = response.userData.userChoices
          }
        })
        .catch(err => {
          this.currState.msgType = 'error'
          this.currState.msg = err
        })
    },
  },
}
</script>

<style>
  #va-table-choice td {
    padding: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 1px solid #dddddd;
  }

  #va-table-choice {
    min-height: 0 !important;
    text-align: center;
  }

  .input-choicetext {
    margin: 0;
  }

  #va-table-choice .va-checkbox__input-container {
    justify-content: center;
  }

  .input-choicetext .va-input__container {
    min-height: 1rem;
    border-radius: 0;
  }

  .choicetext .va-card {
    color: #000000 !important;
  }
</style>
